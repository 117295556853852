import React from 'react'
import tw from 'twin.macro'

import Layout from '../../components/Layout'
import SEO from '../../components/Seo'
import { graphql } from 'gatsby'
import Container from '../../components/Container'
import CategoryNav from '../../components/Post/CategoryNav';
import PostContentGroup from '../../components/Post/ContentGroup';
import { ButtonLink } from '../../components/Button';
import { Button } from 'react-scroll'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';


const BlogPage = ({ data }) => {
    // condense the posts
    const posts = data.allWpPost.edges.map(({node}) => node);
    
    return (
        <Layout>
            <SEO />
            <Container tw="grid mt-10 md:(grid-cols-3) lg:(grid-cols-4) xl:(grid-cols-5) gap-8">
                
                {/* Sticky Categories Nav */}
                <div tw="h-full relative">
                    <CategoryNav />
                </div>

                {/* Blog Posts */}
                <div tw="md:(col-span-2) lg:(col-span-3) xl:(col-span-4)">
                    <PostContentGroup posts={posts}/>

                    <div tw="flex justify-start">
                        <ButtonLink to="/blog/page/1" large>
                            <span tw="mr-3">
                                See More Posts
                            </span>
                            <FontAwesomeIcon icon={faChevronRight} />
                        </ButtonLink>
                    </div>
                </div>

            </Container>
        </Layout>
    )
}

export const query = graphql`
{
    allWpPost(limit: 9, sort: {order: DESC, fields: date}) {
        edges {
            node {
                date
                excerpt
                title
                slug
                featuredImage {
                    node {
                        localFile {
                            childImageSharp {
                                fluid(maxHeight: 800) {
                                    ...GatsbyImageSharpFluid_withWebp
                                }
                            }
                        }
                    }
                }
                categories {
                    nodes {
                        name
                        slug
                    }
                }
            }
        }
        
    } 
    allWpCategory {
        edges {
            node {
                # id
                name
                slug
                # posts {
                #     nodes {
                #         id
                #         slug
                #     }
                # }
            }
        }
    }
}
`

export default BlogPage